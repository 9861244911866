export const apiUrl='https://admin.yashrajjewels.com/api/';
//export const apiUrl="https://api.savyajewelsbusiness.com/api/"
export const IMAGE='https://admin.yashrajjewels.com/img/';
 
// authentication
export const OTPREQUEST='otp/request';
// export const LOGIN = 'user/login';
export const LOGIN='login';
//export const REGISTER = 'user/register';
export const REGISTER='register';
export const OTPVERIFIED = 'user/register/otpverified';
export const OTPRESEND = 'user/register/otpresend';
export const FORGETPASSWORD = 'user/forgetpassword';
export const CHANGEPASSWORD = 'user/update/password';
export const USERKYC = 'user/kyc';
//export const PROFILEUPDATE = 'user/profile/update';
export const PROFILEUPDATE='edit';
//export const PROFILEVIEW = 'user/profile/view';
export const PROFILEVIEW='details';

 // pages
export const CONTACT = 'contact'; //done
export const ABOUT = 'pages/2'; //done
export const TERMSANDCONDITION = 'pages/3';//done
export const PAYMENT = 'pages/1';//done
export const RETURN = 'pages/4';
 // wishlist
//export const WISHLISTVIEW = 'user/wishlist/view';
export const WISHLISTVIEW='wishlist';
// export const WISHLISTADD = 'user/wishlist/store';
export const WISHLISTADD = 'wishlist';
// export const WISHLISTDELETE = 'user/wishlist/delete';
export const WISHLISTDELETE='wishlist';


// cart
//export const CARTVIEW = 'user/cart/view';
export const CARTVIEW='cart/show';
// export const CARTDELETE = 'user/cart/delete';
export const CARTDELETE = 'cart/Delete';
// export const CARTADD = 'user/cart';
export const CARTADD = 'cart'
// export const CARTUPDATE = 'user/cart/qty';
export const CARTUPDATE = 'cart/update';


// Dashboard
// export const CRAUSEL = 'user/dashboard/home';
export const CRAUSEL = 'home';
//export const NAVIGATION = 'admin/app/navigation' ;
export const NAVIGATION = 'home/navigation' ;
export const LIVERATE = 'https://api.yashrajjewelsbusiness.com/api/live_price';

// Address
export const GETADDRESS = 'address'; //done
export const ADDADDRESS = 'address'; //done
export const DELADDRESS = 'address'; //done
export const EDITADDRESS = 'address';//done
export const STATE = 'state';//done
export const CITY = 'cities';//done

// Events
export const EVENTS = 'events'; //done
export const MYEVENTS = 'events'; //done

// Products
export const PRODUCTDETAILS = 'product'; //done
export const CATEGORY = 'category'; //done
export const SUBCATEGORY = 'subcategory'; //done
export const SUBCATEGORYTYPE='subsubcategory'; //done
// export const PRODUCTLIST = 'product/list';
export const PRODUCTLIST = 'productlist';
export const PRODUCTFILTERMENU='menu';
export const PRODUCTFILTER = 'productlist';
export const ORDERBY = 'product/list?subsubcategory_id=';
export const PRICELIST = 'product/list/price';
//export const SEARCH = 'search';
export const SEARCH='product';
export const MERCHANT = 'BulianMerchant'; //done
export const OFFER = 'offer'; //done
export const VERIFY = 'offer/verify'; //done
// order
// export const ORDERHISTORY = 'order/history';
export const ORDERHISTORY = 'checkout'; //done
export const CHECKOUT = 'checkout'; //done
export const BANNER = 'banner'; //done

export const MACHINE = 'machinery';
export const MACHINESEARCH = "machinarylist";
export const MACHINEPRODUCT = "";
//export const MACHINESEARCH = "";
export const BLOG = 'blogs';
export const ASK = 'askprice';
export const LIVEBANNER = 'liverate_banners';