import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from 'ng5-slider/options';
import { ApiService } from 'src/app/api/api.service';
import { NAVIGATION, PRODUCTFILTERMENU, PRODUCTLIST } from 'src/config';
@Component({
  selector: 'app-filter-nav-bar',
  templateUrl: './filter-nav-bar.component.html',
  styleUrls: ['./filter-nav-bar.component.css']
})

export class FilterNavBarComponent implements OnInit {
  jewelery_for:any;
  material:any;
  purity:any;
  price:any;
  jewelery_type:any;
  filter:any;
  hide = 0;
  jfor = 0;
  metf = 0;
  prif = 0;
  purf = 0;
  jewt = 0;
  color: any;
  clarity: any;
  dweight: any;
  pweight: any;
  gweight: any;
  gold: any;
  gems: any;
  options: Options = null;
  options2: Options = null;
  options3: Options = null;
  category: any;
  category2: any;
  ssid: string;
  products: any;
  menu: unknown;
  cg: boolean;
  cp: boolean;
  cd: boolean;
  screen: boolean;
  sssid: string;
  subsubcategory: string;
  constructor(private api: ApiService,private eRef: ElementRef
    ,@Inject(PLATFORM_ID) private _platformId, private route: ActivatedRoute,private router:Router) { 
      this.filter = this.api.getfilter();
      this.screen = isPlatformBrowser(this._platformId);
    }
    @ViewChild('goldzi', { static: true }) a: ElementRef;
    @ViewChild('colorz', { static: true }) b: ElementRef;
    @ViewChild('purityz', { static: true }) c: ElementRef;
    @ViewChild('clarityz', { static: true }) d: ElementRef;
    @ViewChild('diamond_weighta', { static: true }) e: ElementRef;
    @ViewChild('goldz', { static: true }) f: ElementRef;
    @ViewChild('platinumz', { static: true }) g: ElementRef;
    @ViewChild('gemsz', { static: true }) h: ElementRef;
    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(this.eRef.nativeElement.contains(event.target)) {
        
      } else {
        
          var elements = document.querySelectorAll('.active');
          
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }
      }
    }
openclose(value) {
  if(value == 1) {
    if (this.prif==1) {
      this.prif = 0;
    } else {
      this.prif = 1;
      this.jewt = 0;
      this.purf = 0;
      this.jfor = 0;
      this.metf = 0;
    }
  } else if (value == 2) {
    if (this.jewt == 1) {
      this.jewt = 0;
    } else {
      this.jewt = 1;
      this.purf = 0;
      this.prif = 0;
      this.jfor = 0;
      this.metf = 0;
    }

  } else if (value == 3) {
    if (this.purf == 1) {
      this.purf = 0;
    } else {
      this.purf = 1;
      this.prif = 0;
      this.jewt = 0;
      this.jfor = 0;
      this.metf = 0;
    }
  } else if (value == 4) {
    if (this.jfor == 1) {
      this.jfor = 0;
    } else {
      this.jfor = 1;
      this.prif = 0;
      this.jewt = 0;
      this.purf = 0;
      this.metf = 0;
    }
  } else if (value == 5) {
    if (this.metf == 1) {
      this.metf = 0;
    } else {
      this.metf = 1;
      this.prif = 0;
      this.jewt = 0;
      this.purf = 0;
      this.jfor = 0;
    }
  }
}
deleteSelected(type,filterValue){
    let f= this.api.getfilter();
      if(type=="jewelery_type")
      {
        f.jewelery_type=[];
        this.api.setfilter(f);     
      }
      else if(type=="jewelery_for")
      {
          if(f.jewelery_for.length>0)
          {
              let result=f.jewelery_for.find(x => x == filterValue);
              if(result)
              {
                let i= f.jewelery_for.indexOf(result);
                if (i > -1) {
                  f.jewelery_for.splice(i, 1);
                }
                this.api.setfilter(f);
              }
          }
      }
      else if(type=="material")
      {
        if(f.material.length>0)
        {
              let result=f.material.find(x => x == filterValue);
              if(result)
              {
                let i= f.material.indexOf(result);
                if (i > -1) {
                  f.material.splice(i, 1);
                }
                this.api.setfilter(f);
              }
          }
      }
      else if(type=="purity")
      {
        if(f.purity)
          {
            let result=f.purity;
            if(result)
            {
              f.purity = null;
                this.api.setfilter(f);
            }
          }  
      }
      else if(type=="price")
      {
        Object.keys(f.price).forEach(k => delete f.price[k]);
        this.api.setfilter(f);
      }else if(type=="color")
      {
        if(f.color.length>0)
          {
            let result=f.color.find(x => x == filterValue);
            if(result)
            {
              let i= f.color.indexOf(result);
              if (i > -1) {
                f.color.splice(i, 1);
              }
                this.api.setfilter(f);
            }
          }  
      }else if(type=="clarity")
      {
        if(f.clarity.length>0)
          {
            let result=f.clarity.find(x => x == filterValue);
            if(result)
            {
              let i= f.clarity.indexOf(result);
              if (i > -1) {
                f.clarity.splice(i, 1);
              }
                this.api.setfilter(f);
            }
          }  
      }else if(type=="gold")
      {
        f.gold=[];
        this.api.setfilter(f);     
      }else if(type=="gems")
      {
        f.gems=[];
        this.api.setfilter(f);     
      }
}
ngOnDestroy(){
  this.clear();
}
filteropen(value){
  if(value==1){
    this.hide=1;
  } else{
    this.hide=0;
  }
}
checkClear()
{
  
  let f= this.api.getfilter();
  if(isPlatformBrowser(this._platformId)){
  if(f.jewelery_type.length>0||f.jewelery_for.length>0||f.gold.length>0||f.gems.length>0||f.material.length>0||f.purity||f.color.length>0||f.clarity.length>0||Object.keys(f.price).length>0||Object.keys(f.diamond_weight).length>0||Object.keys(f.platinum_weight).length>0||Object.keys(f.gold_weight).length>0)
  {
    return true;
  }
  else{
    return false;
  }}else{return false;}
}
checkP()
{
  let f= this.api.getfilter();
  if(Object.keys(f.price).length>0)
  {
    return true;
  }
  else{
    return false;
  }
}
changefilter(event,value)
{
  let name=event.target.name;

  let f= this.api.getfilter();
  if(name=="jewelery_for")
  {
            if(f.jewelery_for.length>0)
            {
              let result=f.jewelery_for.find(x => x == value);
              if(result)
              {
                let i= f.jewelery_for.indexOf(result);
                if (i > -1) {
                  f.jewelery_for.splice(i, 1);
                }
                this.api.setfilter(f);
              }
              else
              {
                f.jewelery_for.push(value);
                this.api.setfilter(f);
              }
          }
          else
          {
            f.jewelery_for.push(value);
            this.api.setfilter(f);
          }
  }
  else if(name=="jewelery_type")
  { 
    f.jewelery_type=[]
    f.jewelery_type.push(value);
    this.api.setfilter(f);     
  }
  else if(name=="purity")
  {
           f.purity=value;
      this.api.setfilter(f);
  }
  else if(name=="material")
  {
            if(f.material.length>0)
            {
                  let result=f.material.find(x => x == value);
                  if(result)
                  {
                    let i= f.material.indexOf(result);
                    if (i > -1) {
                      f.material.splice(i, 1);
                    }
                    this.api.setfilter(f);
                  }
                  else
                  {
                    f.material.push(value);
                    this.api.setfilter(f);
                  }
          }
          else
          {
            f.material.push(value);
            this.api.setfilter(f);
          }
  }else if(name == 'color'){
    if(f.color.length>0)
    {
          let result=f.color.find(x => x == value);
          if(result)
          {
            let i= f.color.indexOf(result);
            if (i > -1) {
              f.color.splice(i, 1);
            }
            this.api.setfilter(f);
          }
          else
          {
            f.color.push(value);
            this.api.setfilter(f);
          }
  } else
    {
      f.color.push(value);
      this.api.setfilter(f);
    }
  }else if(name == 'clarity'){
    if(f.clarity.length>0)
    {
          let result=f.clarity.find(x => x == value);
          if(result)
          {
            let i= f.clarity.indexOf(result);
            if (i > -1) {
              f.clarity.splice(i, 1);
            }
            this.api.setfilter(f);
          }
          else
          {
            f.clarity.push(value);
            this.api.setfilter(f);
          }
  } else
    {
      f.clarity.push(value);
      this.api.setfilter(f);
    }
  }else if(name == 'goldie'){
            f.gold[0]=value;
            this.api.setfilter(f);
  }else if(name == 'gems'){
      f.gems[0]=value;
      this.api.setfilter(f);
  }
  var elements = document.querySelectorAll('.active'); 
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }

}
abc(a,name) {
  
  var elements = document.querySelectorAll('.active');
  a.classList.toggle("active");
  for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
  }
  
}
getprice(event){
  let min;
  let max;
  let res:any;
  let a=event['target']['value'];
  let f= this.api.getfilter();
  if(a.length<=5)
  {
      min=a;
      max=1000000;
      f.price.min=Number(min);
      f.price.max=Number(max);
      this.api.setfilter(f);
  } else {
      res = a.split("-");
      min =res[0];
      max  =res[1]
      f.price.min=Number(min);
      f.price.max=Number(max);
      this.api.setfilter(f);
  }
    var elements = document.querySelectorAll('.active'); 
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
    }
}
clear()
{
  if(this.dweight){this.checkeddweight(true)}
  if(this.pweight){this.checkedpweight(true)}
  if(this.gweight){this.checkedgweight(true)}
  let initial={"jewelery_for":[],"jewelery_type":[],"material":[],"price":{},"purity":null,"color":[],"clarity":[],"diamond_weight":{},"gold_weight":{},"platinum_weight":{},"gold":[],"gems":[],"subsubcategory":this.sssid};

  this.api.setfilter(initial);
}
checkedprice(min)
{
      let f= this.api.getfilter();
                if(f.price.min==min)
                {
                  return true;
                }
                else
                {
                    return false;
                }
}
checkedjewelery_type(value)
{
    let f= this.api.getfilter();
    if(f.jewelery_type==value)
    {
      return true;
    }
    else
    {
      return false;
    }
}
checkedjewelery_for(value)
{
  let f= this.api.getfilter();
  let result=f.jewelery_for.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }

}
checkedmaterial(value)
{
  let f= this.api.getfilter();
  let result=f.material.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }
}
checkedpurity(value)
{
  let f= this.api.getfilter();
  let result=f.purity;
  if(result == value)
  {
   return true;
  }
  else
  {
    return false;
  }
}
checkedcolor(value)
{
  let f= this.api.getfilter();
  let result=f.color.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }
}
checkedclarity(value)
{
  let f= this.api.getfilter();
  let result=f.clarity.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }
}
checkeddweight(del = false){
  let f = this.api.getfilter();
  if(!del){
      f.diamond_weight.min=Number(this.dweight.min);
      f.diamond_weight.max=Number(this.dweight.max);
      this.api.setfilter(f);
      this.cd = true;
      }else if(del){
        f.diamond_weight = {};
        this.dweight.min=Number(this.options2['floor']);
        this.dweight.max=Number(this.options2['ceil']);
        console.log(this.dweight);
        this.api.setfilter(f);
        this.cd = false;
      }
      this.checkClear();
}
checkedgweight(del = false){
      let f = this.api.getfilter();
      if(!del){
      f.gold_weight.min=Number(this.gweight.min);
      f.gold_weight.max=Number(this.gweight.max);
      this.api.setfilter(f);
      this.cg = true;
      }
      else if(del){
        f.gold_weight={};
        this.gweight.min=Number(this.options['floor']);
        this.gweight.max=Number(this.options['ceil']);
        this.api.setfilter(f);
        this.cg = false;
      }
      this.checkClear()
}
checkedpweight(del = false){
  let f = this.api.getfilter();
  if(!del){
      f.platinum_weight.min=Number(this.pweight.min);
      f.platinum_weight.max=Number(this.pweight.max);
      this.cp = true;
      this.api.setfilter(f);
      }
      else if(del){
        f.platinum_weight={};
        this.pweight.min=Number(this.options3['floor']);
        this.pweight.max=Number(this.options3['ceil']);
        this.api.setfilter(f);
        this.cp = false;
      }
      this.checkClear()
}
checkedgold(value){
  let f= this.api.getfilter();
  let result=f.gold.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }
}
checkedgems(value){
  let f= this.api.getfilter();
  let result=f.gems.find(x => x == value);
  if(result)
  {
   return true;
  }
  else
  {
    return false;
  }
}
optionsupdate(type){
  if(type=='gold'){
    console.log(this)
  this.options = {
    floor:this.gweight.min,
    ceil:this.gweight.max,
    translate: (value: number): string => {
      return value+' g';
    }
  }
  }else if(type=='diamond'){
    this.options2 = {
      floor:this.dweight.min,
      ceil:this.dweight.max,
      step:0.2,
      translate: (value: number): string => {
        return value+' Ct';
      }
    }
  }else if(type == 'platinum'){
    this.options3 = {
      floor:this.pweight.min,
      ceil:this.pweight.max,
      translate: (value: number): string => {
        return value+' g';
      }
    }
  }
}
ngOnInit(){
    this.route.queryParamMap.subscribe(params =>{
      this.ssid =  params.get('category');
      this.sssid = params.get('subcategory');
      if(!this.ssid || !this.sssid){this.router.navigate(['url-not-matched']);}
      this.route.params.subscribe(params => {
        if(this.screen) {
          this.api.Get(PRODUCTFILTERMENU+'/'+this.ssid+'/'+this.sssid).then(data  => {
        this.menu = data;
        this.jewelery_for=data['menu'].jewelery_for;
        this.color = data['menu'].color;
        this.material=data['menu'].material;
        this.dweight=data['menu'].diamond_weight;
        this.filter.subcategory = this.sssid;
        this.gweight=data['menu'].gold_weight;
        this.pweight=data['menu'].platinum_weight;
        
        this.purity=data['menu'].purity;
        this.price=data['menu'].price;
        this.clarity=data['menu'].clarity;
        if(data['menu'].jewelery_type){
        this.jewelery_type=data['menu'].jewelery_type;}
        this.gold = data['menu'].gold;
        
        
      if(this.gweight) {
        this.gweight.min = this.gweight.min*1;
        this.gweight.max = this.gweight.max*1;
        this.optionsupdate('gold');}
      if(this.dweight) {  this.optionsupdate('diamond');}
      
      if(this.pweight) {  this.optionsupdate('platinum');}
        this.gems = data['menu'].gems;
       
      }).catch(d=>{
          });
        }
      });
   });

     this.api.filterChange.subscribe(data=>{
      this.filter=this.api.getfilter();
     })
}

}
