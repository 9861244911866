import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { CONTACT } from 'src/config';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
values:any;
im:boolean;
val:boolean;

child:any;
  id: any;
  url: any;
  constructor(private api:ApiService,public sanitizer:DomSanitizer) { 
  this.im=true;
  this.val=false;
    this.api.Get(CONTACT).then(data=>{
      this.im=false;
  this.val=true;
      this.values=data['data'];
      this.id = 'https://maps.google.com/?ie=UTF8&t=m&ll='+this.values.latitude+','+this.values.longitude+'&spn=0.003381,0.017231&z=16&output=embed';
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.id);
      console.log(this.values);
    }).catch(d=>{
      console.log(d);
    })}


  ngOnInit(): void {
  }

}
