import { SlugPipe, MyCurrencyPipe, SummaryPipe, INRCurrencyPipe, SafePipe } from './../slug.pipe';
import { SubsubComponent } from './../subsub/subsub.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EventsComponent } from './../events/events.component';
import { ExclusiveBannerComponent } from './../../layout/exclusive-banner/exclusive-banner.component';
import {  HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { SliderComponent } from '../slider/slider.component';
import { TestimonialsComponent } from '../testimonials/testimonials.component';
import { FilterProductComponent } from '../filter-product/filter-product.component';
import { ProductHolderComponent } from '../product-holder/product-holder.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EventComponent } from '../event/event.component';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { ShareButtonsConfig, ShareModule } from '@ngx-share/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { FilterNavBarComponent } from '../filter-nav-bar/filter-nav-bar.component';
import { LoaderComponent } from '../loader/loader.component';


// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { CategoryComponent } from 'src/app/site/category/category.component';
import { SubcategoryComponent } from 'src/app/site/subcategory/subcategory.component';
import { LoginComponent } from '../../auth/login/login.component';
import { RegisterComponent } from '../../auth/register/register.component';
import { ChangeProfileComponent } from '../../auth/change-profile/change-profile.component';
import { ContactComponent } from '../../layout/contact/contact.component';
import { AboutComponent } from '../../layout/about/about.component';
import { TermsAndConditionComponent } from '../../layout/terms-and-condition/terms-and-condition.component';
import { PaymentComponent } from '../../layout/payment/payment.component';
import { SearchComponent } from '../../layout/search/search.component';
import { PrivacyComponent } from '../../layout/privacy/privacy.component';
import { CheckoutComponent } from '../../shopping/checkout/checkout.component';
import { CartComponent } from '../../shopping/cart/cart.component';
import { AccountDetailsComponent } from '../../my-account/account-details/account-details.component';
import { AccountAddressesComponent } from '../../my-account/account-addresses/account-addresses.component';
import { AccountWishlistComponent } from '../../my-account/account-wishlist/account-wishlist.component';
import { AccountHistoryComponent } from '../../my-account/account-history/account-history.component';
import { EditAddressComponent } from '../../my-account/edit-address/edit-address.component';
import { EventHistoryComponent } from '../../my-account/event-history/event-history.component';
import { OrderDetailComponent } from '../../my-account/order-detail/order-detail.component';
import { OffersComponent } from '../../my-account/offers/offers.component';
import { LoginGuardService } from '../../auth-guard/login-guard.service';
import { AuthGuardService } from '../../auth-guard/auth-guard.service';
import { CheckoutService } from '../../auth-guard/checkout.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeoService } from '../../SEO/seo.service';
import { SelltermsComponent } from '../sellterms/sellterms.component';
import { Ng5SliderModule } from 'ng5-slider';


const customConfig: ShareButtonsConfig = {
  autoSetMeta: true,
  twitterAccount: ''
};




@NgModule({
  declarations: [
    HomeComponent,
    SliderComponent,
    TestimonialsComponent,
    FilterProductComponent,
    ProductHolderComponent,
    ExclusiveBannerComponent,
    EventsComponent,
    EventComponent,
    ProductDetailsComponent,
    SubsubComponent,
    LoaderComponent,
    FilterNavBarComponent,
    CategoryComponent,
    SubcategoryComponent,
    LoginComponent,
    RegisterComponent,
    ChangeProfileComponent,
    ContactComponent,
    AboutComponent,
    TermsAndConditionComponent,
    PaymentComponent,
    SearchComponent,
    PrivacyComponent,
    CheckoutComponent,
    CartComponent,
    AccountDetailsComponent,
    AccountAddressesComponent,
    AccountWishlistComponent,
    AccountHistoryComponent,
    EditAddressComponent,
    EventHistoryComponent,
    OrderDetailComponent,
    OffersComponent,
    SlugPipe,
    MyCurrencyPipe,
    SummaryPipe,
    SafePipe,
    INRCurrencyPipe,
    SelltermsComponent,

  ],
  imports: [
    FontAwesomeModule,
    Ng5SliderModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgxImageZoomModule,
    SimpleNotificationsModule.forRoot({
      position:["bottom","left"],
       timeOut: 3000,
       showProgressBar: true,
       pauseOnHover: true,
       clickToClose: true
     }),
    ShareModule.withConfig(customConfig),
    RouterModule.forChild([
      { path: 'changeProfile' , component: ChangeProfileComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'account-details', component: AccountDetailsComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'account-addresses', component: AccountAddressesComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'account-wishlist', component: AccountWishlistComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'account-history', component: AccountHistoryComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'edit-address/:id', component: EditAddressComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'add-address', component: EditAddressComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'event-history', component: EventHistoryComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'order-detail/:id', component: OrderDetailComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'offers', component: OffersComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'checkout', component: CheckoutComponent,  canActivate: [AuthGuardService]}, //done
      { path: 'cart', component: CartComponent, canActivate: [AuthGuardService]},
      { path: 'contact', component: ContactComponent}, //map
      { path: 'about', component: AboutComponent}, //no page
      { path: 'terms', component: TermsAndConditionComponent}, //done
      { path: 'payment', component: PaymentComponent}, //done
      { path: 'search/:value', component: SearchComponent}, //done
      { path: 'privacy', component: PrivacyComponent}, //no data for now
      { path: 'home', component: HomeComponent},  //done
      { path: 'manufacture/:id', component: CategoryComponent}, //done
      { path: 'events' , component: EventsComponent}, //done
      { path: 'event/:id' , component: EventComponent}, //done
      { path: 'product-details/:id' , component: ProductDetailsComponent},
      { path: 'login' , component: LoginComponent,  canActivate: [LoginGuardService]},
      { path: 'register' , component: RegisterComponent,  canActivate: [LoginGuardService]},
     // { path: 'jewellery/:id' , component: ProductDetailsComponent},
     //done
      { path: 'filternav' , component: FilterNavBarComponent}, //done
      { path: 'jewellery/cart/:product', component: ProductDetailsComponent}, //done
      { path: 'jewellery/manufacture/:id', component: CategoryComponent}, //done
      { path: 'jewellery/manufacture/:idm/:subcategory', component: SubcategoryComponent},
      { path: 'jewellery/manufacture/:idm/:subcategory/:subsubcategory' , component: FilterProductComponent}, //done
      { path: 'jewellery/:subcategory', component: SubcategoryComponent}, //done
      { path: 'jewellery/:subcategory/:subsubcategory' , component: FilterProductComponent}, //done
      { path: 'jewellery/:cat/:sub/:product' , component: ProductDetailsComponent},
    ])
  ],
  exports: [LoaderComponent,NgbModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgxImageZoomModule,SlugPipe]  ,
    providers: [SeoService],
})
export class SiteModule { }
