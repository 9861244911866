import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellterms',
  templateUrl: './sellterms.component.html',
  styleUrls: ['./sellterms.component.css']
})
export class SelltermsComponent implements OnInit {
  im: boolean;
  val: boolean;
  values2: any;
  values(values: any) {
    throw new Error("Method not implemented.");
  }

  constructor() { 
   
  }

  ngOnInit(): void {
  }

}
