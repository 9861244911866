import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faSms } from '@fortawesome/free-solid-svg-icons/faSms';
 
export const iconpack = [
    faFacebookF, faTwitter, faLinkedinIn, faRedditAlien,
    faTelegramPlane, faWhatsapp, faEnvelope, faPrint, faSms
];