import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {
  drop: any;
  constructor(private router: Router, private api: ApiService) {
    this.drop = this.api.drop;
   }
  canActivate(route, state: RouterStateSnapshot) {
    this.api.getlogin.subscribe(data => {
      this.drop = data;
    });
    if (this.drop == 0 ) {
      return true;
     } else if (this.drop == 1) {
       this.api.onSuccess('You have already Login ');
       this.router.navigate(['home']);
       return false;
      }
    }
}
